<template>
  <div>
    <iframe tabindex=0 ref="webview"  class="webwiew"></iframe>
    <div class="webwiew loading" v-show="isLoading" @click="close()"> loading....</div>
  </div>
</template>
<script>
import { keyMap } from '@/util/keyUtil.js'
export default {
  mounted () {
    const url = this.$route.query.path
    this.$refs.webview.contentWindow.location.href = url
    window.addEventListener('message', this.close)
    window.eventBus.addEventListener('message', this.onKeydown)
    this.$refs.webview.addEventListener('load', this.loaded)
  },
  data () {
    return {
      isLoading: false
    }
  },
  created () {
    this.isLoading = true
  },
  methods: {
    close () {
      console.log('.....')
      // this.$refs.webview.contentWindow.location.href = 'about:blank'
      this.$router.replace('/')
    },
    onKeydown (e) {
      const type = keyMap[e.keyCode]
      this.$refs.webview.contentWindow.postMessage(type, '*')
    },
    loaded () {
      console.log('end......')
      this.isLoading = false
    }
  },
  beforeDestroy () {
    window.removeEventListener('message', this.close)
    window.eventBus.removeEventListener('message', this.onKeydown)
    this.$refs.webview.removeEventListener('load', this.loaded)
  }
}
</script>
<style lang="scss" scoped>
.webwiew{
  width:1280px;
  height: 750px;
  background-color: #2f2f2f;
  overflow: hidden;
  border: 0;
}
.loading{
  position: absolute;
    text-align: center;
    line-height: 750px;
    z-index: 10000;
    top: 0px;
}
</style>
